








import { Component, Vue } from 'vue-property-decorator'
import __ from '@/shared/helpers/__'
import PageHeader from '@/admin/components/PageHeader.vue'
import Form from '@/shared/components/form/Form.vue'
import FormBase from '@/shared/classes/form/form-base'
import Field, { FieldSizes } from '@/shared/classes/form/field'
import { FieldTypes } from '@/shared/components/form/field-types'
import { AdminRoutes } from '@/shared/router/admin'
import SelectField from '@/shared/classes/form/fields/Select/select-field'
import { LOCALES } from '@/shared/constants/locales.constants'
import SelectItem from '@/shared/classes/form/fields/Select/select-item'
import { GlobalActions } from '@/shared/store/global/global.actions'
import { SnackBarTypes } from '@/shared/helpers/snack-bar.helper'
import SearchableField from '@/shared/classes/form/fields/Select/searchable-field'
import Task from '@/shared/modules/task/task.model';
import User from '@/shared/modules/user/user.model';

@Component({
  components: { Form, PageHeader },
  methods: {
    __
  }
})
export default class UsersCreate extends Vue {
  form: FormBase = new FormBase()
    .setEndpoint('/users')
    .setFiles(true)
    .setFields([
      new Field()
        .setType(FieldTypes.file)
        .setKey('avatar')
        .setTitle(__('admin.views.users.form.avatar'))
        .setMeta({ type: 'image', accept: 'image/*' }),
      new Field()
        .setKey('first_name')
        .setEntryKey('firstName')
        .setTitle(__('admin.views.users.form.first_name'))
        .setSize(FieldSizes.half),
      new Field()
        .setEntryKey('lastName')
        .setKey('last_name')
        .setTitle(__('admin.views.users.form.last_name'))
        .setSize(FieldSizes.half),
      new Field()
        .setType(FieldTypes.email)
        .setKey('email')
        .setTitle(__('admin.views.users.form.email')),
      new Field()
        .setKey('phone')
        .setTitle(__('admin.views.users.form.phone'))
        .setSize(FieldSizes.half),
      new Field()
        .setType(FieldTypes.datePicker)
        .setEntryKey('birthDate')
        .setKey('birth_date')
        .setTitle(__('admin.views.users.form.birth_date'))
        .setSize(FieldSizes.half),
      new Field()
        .setKey('address')
        .setTitle(__('admin.views.users.form.address')),
      new SelectField()
        .setKey('role_uuid')
        .setEntryKey('roleUuid')
        .setTitle(__('admin.views.users.form.role'))
        .loadItems({ endpoint: '/roles', value: 'uuid', title: 'name',  })
        .setSize(FieldSizes.half),
      new SearchableField()
        .setKey('company_uuid')
        .setEntryKey('companyUuid')
        .setTitle(__('admin.views.users.form.company'))
        .loadItems({ endpoint: '/companies', value: 'uuid', title: 'name', perPage: 15 })
        .setSize(FieldSizes.half),
      new SelectField()
        .setKey('position_uuid')
        .setEntryKey('positionUuid')
        .setTitle(__('admin.views.users.form.position'))
        .loadItems({ endpoint: '/positions', value: 'uuid', title: 'name',  })
        .setSize(FieldSizes.half),
      new SelectField()
        .setEntryKey('defaultLocale')
        .setKey('default_locale')
        .setTitle(__('admin.views.users.form.default_locale'))
        .setItems(Object.keys(LOCALES).map((key: string): SelectItem => new SelectItem()
          .setTitle(LOCALES[key].title)
          .setValue(key)
        ))
        .setSize(FieldSizes.half),
      new Field()
        .setEntryKey('clothingSize')
        .setKey('clothing_size')
        .setTitle(__('admin.views.users.form.clothing-size'))
        .setSize(FieldSizes.half),
      new Field()
        .setEntryKey('shoeSize')
        .setKey('shoe_size')
        .setTitle(__('admin.views.users.form.shoe-size'))
        .setSize(FieldSizes.half),
      new Field()
        .setType(FieldTypes.datePicker)
        .setEntryKey('workFrom')
        .setKey('work_from')
        .setTitle(__('admin.views.users.form.work_from'))
        .setSize(FieldSizes.half),
      new Field()
        .setType(FieldTypes.datePicker)
        .setEntryKey('workTo')
        .setKey('work_to')
        .setTitle(__('admin.views.users.form.work_to'))
        .setSize(FieldSizes.half),
      new SelectField()
        .setKey('status_uuid')
        .setEntryKey('statusUuid')
        .setTitle(__('admin.views.users.form.status'))
        .loadItems({ endpoint: `statuses/model/${ User.ALIAS }`, value: 'uuid', title: 'name' })
        .setSize(FieldSizes.half),
    ])
    .setInitialValues({
      statusUuid: User.STATUS_ACTIVE,
    })
    .setSubmit({
      text: __('admin.views.users.create.form.submit')
    })
    .setOnSuccess(this.onSuccess)

  goBack(): void {
    this.$router.push({ name: AdminRoutes.usersIndex })
  }

  private onSuccess(): void {
    this.$store.dispatch(GlobalActions.showSnackBar, {
      type: SnackBarTypes.success,
      message: __('admin.views.users.create.form.on-success')
    })
    this.goBack()
  }
}
